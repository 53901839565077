// src/extension/extension.js
import React, { useState } from 'react';
import PageComponent from './components/PageComponent';

const Extension = () => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText('chrome://extensions/').then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Hide message after 2 seconds
    });
  };

  return (
    <PageComponent title="How to try early preview extension">
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">1. Download YKYR extension</h2>
        <p className="leading-relaxed">
          Click the button below to download the ZIP file for the preview version of the extension.
        </p>
        <a
          href="/download/ykyr-extension-0.0.1.zip"
          download
          className="bg-[#405783] text-[#f9ecd6] px-6 py-3 mt-4 inline-block rounded-lg font-semibold text-lg hover:opacity-90 transition-opacity"
        >
          Download
        </a>
      </section>

      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">2. Unzip the File</h2>
        <p className="leading-relaxed">
          After downloading, unzip <strong>ykyr-extension-0.0.1.zip</strong> file to access the extension folder.
        </p>
      </section>

      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">3. Open Chrome Extensions Page</h2>
        <p className="leading-relaxed">
          In your Chrome browser, open the Extensions page by typing or copying the following URL:
        </p>
        <div className="flex items-center mt-2">
          <pre className="bg-gray-100 p-3 rounded-lg text-sm mr-2">chrome://extensions/</pre>
          <button
            onClick={handleCopy}
            className="bg-[#856421] text-[#f9ecd6] px-4 py-2 rounded-lg font-medium text-sm hover:opacity-90 transition-opacity"
          >
            Copy
          </button>
        </div>
        {copySuccess && (
          <p className="text-[#7A9BDE] text-sm mt-2">Copied to clipboard!</p>
        )}
      </section>

      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">4. Enable Developer Mode</h2>
        <p className="leading-relaxed">
          On the Extensions page, switch on <strong>Developer Mode</strong> in the top right corner.
        </p>
      </section>

      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">5. Load the Unpacked Extension</h2>
        <p className="leading-relaxed">
          Click on the <strong>Load unpacked</strong> button, then select the unzipped extension folder you downloaded.
        </p>
      </section>

      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">6. Installation Complete</h2>
        <p className="leading-relaxed">
          YKYR extension is now installed! Create an account and start getting rewards!
        </p>
      </section>
    </PageComponent>
  );
};

export default Extension;
